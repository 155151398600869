<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- УДАЛИТЬ -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            :disabled="$_.isEmpty(admins.selected)"
            :loading="loading.deleteButton"
            v-on="on"
            @click="deleteSelected"
          >
            <v-icon size="18">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <span>Удалить</span>
      </v-tooltip>
    </div>

    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ДОБАВИТЬ АДМИНА -->
      <v-tooltip
        v-if="profile.manageAdmins"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            v-on="on"
            @click="addAdmin"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Добавить админа</span>
      </v-tooltip>

      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('admins/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>

      <!-- ПОИСК -->
      <!-- <v-text-field
        :value="topbarSearch.searchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { mapFields } from 'vuex-map-fields';

export default {
  name: 'AdminsTopbar',

  computed: {
    ...mapGetters({
      admins: 'admins/admins',
      loading: 'admins/loading',
      profile: 'main/profile',
    }),
    // ...mapFields('admins', ['topbarSearch']),
  },

  methods: {
    async deleteSelected() {
      try {
        this.$store.dispatch('admins/setLoading', {
          key: 'deleteButton',
          value: true,
        });

        const response = await this.api.delete('/admin/admins', {
          data: {
            ids: this.admins.selected.map((i) => i.id),
          },
        });
        if (response.status === 200) {
          this.$store.dispatch('admins/clearSelected');
          this.$store.dispatch('admins/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.$store.dispatch('admins/setLoading', {
          key: 'deleteButton',
          value: false,
        });
      }
    },

    async addAdmin() {
      await this.$store.dispatch('admins/setAdminForMainDialog');
      this.$store.dispatch('admins/openDialog', 'mainDialog');
    },

    // search(name) {
    //   this.topbarSearch.searchInput = name;
    //   setTimeout(async () => {
    //     if (name === this.topbarSearch.searchInput) {
    //       this.$store.dispatch('admins/loadAdmins', { query: this.topbarSearch.searchInput });
    //     }
    //   }, 500);
    // },
  },
};
</script>
