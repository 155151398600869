<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="admins.selected"
      :items="admins.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46"
      :items-per-page="-1"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
    >
      <!-- ИМЯ -->
      <template #[`item.displayName`]="{item: admin}">
        <span
          class="main-table-link"
          @click="editAdmin(admin)"
        >
          {{ admin.displayName }}
        </span>
      </template>

      <!-- ПРАВА -->
      <template #[`item.permissions`]="{item: admin}">
        <div class="my-3">
          <div
            v-for="permission in Object.keys(admin)"
            :key="`admins-main-table-permission-${permission}`"
          >
            <span v-if="permissions.includes(permission) && admin[permission]">
              <v-icon
                :size="16"
                color="success"
              >
                mdi-checkbox-marked
              </v-icon>
              {{ getTranslate(permission) }}
            </span>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'AdminsMainTable',

  data() {
    return {
      permissions: [
        'manageAdmins',
        'manageUsers',
        'deleteTeam',
        'managePromocodes',
        'deletePromocodes',
        'manageReleases',
        'manageBrowserBuilds',
        'deleteBrowserBuilds',
        'manageLogs',
        'manageBrowserProfiles',
        'viewReferalBalance',
        'resetReferalBalance',
        'managePromoAccess',
        'changeReferalPercent',
        'setBranch',
        'viewPromocodes',
        'addSubscriptionToEverybody',
        'recurrentPayments',
        'manageRefbringers',
        'managePayments',
        'manageFinanceLogs',
        'manageValues',
        'manageMarketingCases',
        'manageCaptcha',
      ],
    };
  },

  computed: {
    ...mapGetters({
      loading: 'admins/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'admins/pagination',
    }),
    ...mapFields('admins', ['admins']),

    cols() {
      const cols = [];

      cols.push({ text: 'Почта', value: 'username' });
      cols.push({ text: 'Имя', value: 'displayName' });
      cols.push({ text: 'Роль', value: 'roleName' });
      cols.push({ text: 'Права', value: 'permissions' });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    async editAdmin(admin) {
      await this.$store.dispatch('admins/setAdminForMainDialog', admin);
      this.$store.dispatch('admins/openDialog', 'mainDialog');
    },

    updateFooterOptions(options) {
      this.$store.dispatch('admins/loadAdmins', { page: options.page, itemsPerPage: options.itemsPerPage });
    },

    getTranslate(permission) {
      switch (permission) {
        case 'manageAdmins':
          return 'Управлять админами';

        case 'manageUsers':
          return 'Управлять пользователями';

        case 'deleteTeam':
          return 'Удалять команды и учётные записи';

        case 'viewPromocodes':
          return 'Видеть промокоды';

        case 'managePromocodes':
          return 'Управлять промокодами';

        case 'deletePromocodes':
          return 'Удалять промокоды';

        case 'manageReleases':
          return 'Управлять релизами';

        case 'manageBrowserBuilds':
          return 'Управлять билдами браузера';

        case 'deleteBrowserBuilds':
          return 'Удалять билды браузера';

        case 'manageLogs':
          return 'Логи';

        case 'manageBrowserProfiles':
          return 'Браузерные профили';

        case 'viewReferalBalance':
          return 'Видеть реферальный баланс';

        case 'resetReferalBalance':
          return 'Обнулять реферальный баланс';

        case 'changeReferalPercent':
          return 'Менять процент рефских отчислений у юзера';

        case 'managePromoAccess':
          return 'Выдавать промо доступы';

        case 'setBranch':
          return 'Менять ветки (себе и другим)';

        case 'addSubscriptionToEverybody':
          return 'Добавлять подписку всем юзерам в антике одновременно';

        case 'recurrentPayments':
          return 'Управлять рекуррентными платежами (изменение курса подписки, отмена подписки...)';

        case 'manageRefbringers':
          return 'Управлять рефоводами';

        case 'managePayments':
          return 'Управлять платежами';

        case 'manageFinanceLogs':
          return 'Финансовые логи';

        case 'manageValues':
          return 'Управлять стоимостями';

        case 'manageMarketingCases':
          return 'Управлять маркетинговыми условиями';

        case 'manageCaptcha':
          return 'Управлять капчей';

        default:
          return permission;
      }
    },
  },
};
</script>
