<template>
  <div>
    <main-dialog v-if="dialogs.mainDialog" />
    <topbar />
    <main-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainTable from '@/components/admins/AdminsMainTable.vue';
import Topbar from '@/components/admins/AdminsTopbar.vue';
import MainDialog from '@/components/admins/AdminsMainDialog.vue';

export default {
  name: 'Admins',

  components: {
    MainTable,
    Topbar,
    MainDialog,
  },

  computed: {
    ...mapGetters({
      dialogs: 'admins/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('admins/loadAdmins');
  },

  beforeDestroy() {
    this.$store.dispatch('admins/clearSelected');
  },
};
</script>
